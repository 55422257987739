<template>
	<div>
		<common-table
				:itemScope="['ostatus', 'roleid']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				@search="search"
				@remove="remove"
				@save="save"
				@edit="edit"
				ref="ct"
				:leftConfig="leftConfig"
				:rightConfig="rightConfig"
				:listDeploy="listDeploy"
				pageTitle="用户"
				pageKey="userid"
				:btnActionEvent="btnActionEvent"
				@otherTableOperate="otherTableOperate"
		>
			<template v-slot:ostatus="{ item }">
				<w-switches
						inset
						label=""
						rightCode="83705"
						color="var(--v-primary-base)"
						@change="updateStatus(item)"
						v-model="item.ostatus" hide-details dense/>
			</template>
			<template v-slot:roleid="{ item }">
				<template v-for="(role, index) in item.roleid.split(',')">
					<v-chip
							:key="'roleid_' + index"
							v-if="role"
							style="color: #333333; margin-right: 10px"
							color="var(--v-primary-base)"
							outlined
							label
							small
					>
						{{ role }}
					</v-chip>
				</template>
			</template>
		</common-table>
		<exhibition-data-box v-if="dialog" :dialog.sync="dialog" :width="492" :contentHeight="406" title="重置密码">
			<reset-password :userid="userid" @closeDialog="dialog=false"/>
		</exhibition-data-box>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/systemManage/user.js';
import resetPassword from "@/components/resetPassword/resetPassword.vue";
import exhibitionDataBox from '@/components/exhibition-data-box/exhibition-data-box.vue';

export default {
	name: "user",
	components: {resetPassword, exhibitionDataBox},
	mixins: [commonCurd],
	data() {
		return {
			api,
			dialog: false,
			userid: '',
			leftConfig: [
				{type: 'input', label: '输入用户ID/名称', property: 'searchkey'},
				{type: 'select', label: '选择角色', property: 'searchGroup', items: []}
			],
			rightConfig: [
				{label: '新建', icon: 'zengjia', event: 'add', rightCode: '83702'}
			],
			headers: [
				{text: '用户ID', value: 'userid'},
				{text: '用户名称', value: 'username'},
				{text: '角色', value: 'roleid'},
				{text: '状态', value: 'ostatus'},
				{text: '操作', value: 'actions', width: 120}
			],
			btnActionEvent: [
				{
					event: 'editItem',
					icon: 'bianji2-fill',
					color: 'primary',
					tooltip: '编辑',
					rightCode: '83702'
				}
				, {
					event: 'deleteItem',
					icon: 'shanchu-fill',
					color: 'var(--v-redness-base)',
					tooltip: '删除',
					rightCode: '83703'
				},
				{
					event: 'editPassword',
					icon: 'mima-fill',
					color: 'primary',
					tooltip: '修改密码',
					rightCode: '83704'
				}
			],
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'userid',
						connector: {
							props: {
								required: true,
								label: "用户ID"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'username',
						connector: {
							props: {
								required: true,
								label: "用户名称"
							}
						}
					},
          {
            cols: 6,
            subassembly: "VTextField",
            property: 'tel',
            connector: {
              props: {
                required: false,
                label: "手机号"
              }
            }
          },
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'roleid',
						connector: {
							props: {
								required: true,
								label: "选择角色",
								arrayType: false,
								multiple: true,
								items: []
							}
						}
					},
					{
						cols: 6,
						subassembly: "WSwitches",
						disabledDeleteDefault: true,
						property: 'ostatus',
						connector: {
							props: {
								label: '状态',
								inset: true,
								dense: true
							}
						},
						default: true
					}
				]
			}
		}
	},
	methods: {
		// 加载角色列表
		loadRoleList(callback) {
			this.axios.post(
					this.select_data, {keyname: "userrole"}
			).then((res) => {
				if (res.code === this.staticVal.Code.Success) {
					this.roleMap = res.data[0].values;
					this.leftConfig[1].items = this.roleMap;
					this.listDeploy.row[3].connector.props.items = this.roleMap;
					if (callback) {
						callback();
					}
				}
			});
		},
		updateStatus(item) {
			this.axios.post(this.api.updateStatus, {sqlid: item.sqlid, switchop: !item.ostatus}).then((res) => {
				if (res.code === this.staticVal.Code.Success) {
					this.snackbar.success('更新成功');
				}
			});
		},
		otherTableOperate(event, item) {
			if (event === this.btnActionEvent[2].event) {
				this.userid = item.userid;
				this.dialog = true;
			}
		}
	},
	mounted() {
		this.loadRoleList()
	}
}
</script>

<style scoped lang="scss">

</style>
